<template>
  <div class="merchants">
    <div class="listHeader SecondNav">
      <el-tabs v-model="activeName" type="card" @tab-click="activeNameClick">
        <el-tab-pane label="全部" name="1" />
        <!-- <el-tab-pane label="药品" name="2" />
        <el-tab-pane label="医疗器械" name="3" /> -->
      </el-tabs>
    </div>
    <div class=" p-lr-20">
      <div class="searchView  flex a-center m-bottom-10 j-between">
        <div class="flex">
          <div v-if="$minCommon.checkMeau('cplbexport', userBottons)" class="out min-border-right flex a-center f12 m-right-10 pointer ModelBttton-white-30" @click="exportData">
            <i class="el-icon-upload2 f15  p-right-10 " />   导出
          </div>
          <div v-if="$minCommon.checkMeau('cplbfieldmanage', userBottons)" class="out flex a-center f12 m-right-10 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
            <i class="el-icon-setting f15 p-right-5" />   字段管理
          </div>
        </div>
        <div v-if="$minCommon.checkMeau('cplbview', userBottons)" class="flex SearchBox_30" style="flex:1">
          <el-input v-model="listParas.Keyword" size="medium  " placeholder="可通过物料名称、物料号、DI码、物料简称、品牌、创建人、备注、规格搜索" class="rule-input-edit">
            <template slot="prepend">
              <div class="pointer" @click="OpenAdvanSearch">
                <i class="el-icon-s-operation" />高级搜索</div>
            </template>
            <div slot="append" @click="getTableDataAsync(1)">搜  索</div>
          </el-input>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="tableDataloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 315px)"
        row-key="id"
        border
        @select-all="selectAll"
        @select="select"
        @selection-change="handleSelectionChange"
      >
        <af-table-column
          fixed="left"
          type="selection"
          width="40"
        />
        <af-table-column
          width="105"
          label="当前状态"
          prop="Status"
          filter-placement="bottom-end"
          :filter-method="filterTag"
          :filters="[{text: '正常', value: 1}, {text: '待启用', value: 0}, {text: '已禁用', value: 2}, {text: '待首营', value: 4}, {text: '首营中', value: 5}]"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.Status === 1" class="status" size="mini" type="success" icon="el-icon-check" circle />
            <el-button v-if="scope.row.Status === 2" class="status" size="mini" type="info" icon="el-icon-minus" circle />
            {{ scope.row.StateStr }}
          </template>
        </af-table-column>
        <template v-for="(item,index) in tableListJson.productInfolist">
          <af-table-column
            v-if="item.isChecked && item.prop !== 'MaterialTypeName' && item.prop !== 'ApplyDate' && item.prop !== 'ShelfLifeType' && item.prop !== 'RegisterEnd' && item.prop !== 'Tax'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          />
          <af-table-column
            v-if="item.isChecked && item.prop === 'ApplyDate'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <!-- 申请日期 -->
            <template slot-scope="scope">
              {{ $minCommon.setDateFormate(scope.row.ApplyDate) }}
            </template>
          </af-table-column>

          <!-- 物料类型 -->
          <af-table-column
            v-if="item.isChecked && item.prop === 'MaterialTypeName'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.MaterialTypeName }}</span>/<span>{{ scope.row.MaterialSubTypeName }}</span>
            </template>
          </af-table-column>

          <!-- 产品有效期 -->
          <af-table-column
            v-if="item.isChecked && item.prop === 'ShelfLifeType'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.ShelfLifeType === 0">长期</span>
              <span v-if="scope.row.ShelfLifeType > 0">{{ scope.row.ShelfLifeNums }} {{ getShefType(scope.row.ShelfLifeType).text }}</span>
            </template>
          </af-table-column>
          <!-- 证书截止日期 -->
          <af-table-column
            v-if="item.isChecked && item.prop === 'RegisterEnd'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ (scope.row.RegisterEnd !== null && scope.row.RegisterEnd.length > 0) ? $minCommon.formatDate(new Date(scope.row.RegisterEnd), 'yyyy-MM-dd') : '' }}
            </template>
          </af-table-column>
          <!-- 产品税率 -->
          <af-table-column
            v-if="item.isChecked && item.prop === 'Tax'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ getTaxInfo(scope.row.Tax) }}
            </template>
          </af-table-column>
        </template>
        <af-table-column
          fixed="right"
          prop="address"
          align="center"
          label="操作"
          width="50"
        >
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="50"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('cplbview', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="$router.push({ name: 'ProductApprove1', params: { type: 2, idx: scope.row.IDX }})"><i class="el-icon-view p-right-10" />查看</el-link>
                </div>
                <div v-if="$minCommon.checkMeau('cplbedit', userBottons) && [4,5].indexOf(scope.row.Status) === -1 " class="OperationButton">
                  <el-link :underline="false" @click="() => { $router.push({ name: 'productEdit1', params: { type: 2, idx: scope.row.IDX }}) }"><i class="el-icon-edit p-right-10" />编辑</el-link>
                </div>
                <!-- 0：待启用，1：已启用，2：已禁用 -->
                <div v-if="scope.row.Status === 1 && $minCommon.checkMeau('cplbdisable', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="EnableProduct(scope.row.IDX, 2)"><i class="el-icon-circle-close p-right-10" />禁用</el-link>
                </div>
                <div v-if="scope.row.Status === 2 && $minCommon.checkMeau('cplbenable', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="EnableProduct(scope.row.IDX, 1)"><i class="el-icon-circle-check p-right-10" />启用</el-link>
                </div>
                <!-- <div class="min-border-bottom m-tb-10">
                    <i class="el-icon-delete p-right-10" />
                    <span><el-link type="primary" :underline="false" @click="DelteProduct(scope.row.IDX)">删除</el-link></span>
                  </div> -->
                <div v-if="$minCommon.checkMeau('cplbdownload', userBottons) && [4,5].indexOf(scope.row.Status) === -1" class="OperationButton">
                  <el-link :underline="false" @click="downloadZip(scope.row.FileJsonStr)"><i class="el-icon-download p-right-10" />下载</el-link>
                </div>
                <div v-if="(scope.row.Status === 4) && $minCommon.checkMeau('cplbfqsy', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="rechaFirst(scope.row.IDX)"><i class="el-icon-ship p-right-10" />发起首营</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="tableTotalSize"
          :current-page="listParas.PageIndex"
          :page-size="listParas.PageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="listhandleCurrentChange"
        />
      </div>
    </div>
    <el-dialog class="DepartmentBox InfoBoxTwo5" title="产品高级搜索" :visible.sync="AdvancedsearchVisibel" width="900px">
      <el-button style="float:right" size="mini" plain @click="clearAdvan">清空搜索条件</el-button>
      <el-form label-position="right" label-width="120px" :inline="true" :model="listParas" size="mini" class="advanser">
        <el-form-item label="注册证号/批准文号/备案凭证号">
          <el-input
            v-model="listParas.RegisterNo"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="DI码">
          <el-input
            v-model="listParas.DINum"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            v-model="listParas.ApplyDateStart"
            type="date"
            style="width:calc((100% - 15px) / 2)"
            value-format="yyyy-MM-dd"
          />
          -
          <el-date-picker
            v-model="listParas.ApplyDateEnd"
            type="date"
            style="width:calc((100% - 15px) / 2)"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="物料名称">
          <el-input
            v-model="listParas.MaterialName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料简称">
          <el-input
            v-model="listParas.NickName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="品牌">
          <el-input
            v-model="listParas.BrandName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料分类">
          <el-select v-model="listParas.InvTypeName" placeholder="" filterable>
            <el-option v-for="matertypeitem in materialTypeList" :key="matertypeitem.IDX" :label="matertypeitem.InvTypeName" :value="matertypeitem.InvTypeCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="物料类型">
          <el-input
            v-model="listParas.MaterialTypeName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="生产厂家">
          <el-input
            v-model="listParas.ProductFactory"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="生产企业许可证号">
          <el-input
            v-model="listParas.ProductLicenseNo"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料编号">
          <el-input
            v-model="listParas.InvCode"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <!-- <el-form-item label="规格/型号">
          <el-input
            v-model="listParas.Specification"
            size="mini"
            placeholder=""
          />
        </el-form-item> -->
        <el-form-item label="单位">
          <el-input
            v-model="listParas.StockUnitN"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="储存条件">
          <el-select v-model="listParas.StorageConditionName" placeholder="" filterable>
            <el-option v-for="item in ConditionList" :key="item.IDX" :label="item.StorageConditionName" :value="item.IDX" />
          </el-select>
        </el-form-item>
        <el-form-item label="存货代码">
          <el-input
            v-model="listParas.StorageNo"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="原厂编号">
          <el-input
            v-model="listParas.ManuPartNum"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="原产地">
          <el-input v-model="listParas.SourceAreaName" placeholder="请选择原产地" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="selSource" />
          </el-input>
        </el-form-item>
        <el-form-item label="当前状态">
          <el-select v-model="listParas.ApproveState">
            <el-option v-for="item in ApproveStateLisst" :key="item.id" :label="item.value" :value="item.id" />
            <el-option label=" " :value="-1" :disabled="true" />
          </el-select>
        </el-form-item>
        <el-form-item label="物料所在仓库">
          <!-- <el-input
            v-model="listParas.StockCode"
            size="mini"
            placeholder=""
          /> -->
          <el-select v-model="listParas.StockCode">
            <el-option v-for="(item,index) in StockCodeList" :key="index" :label="item.StockCode" :value="item.StockCode" />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="caozuo p-tb-20 t_line_s m-top-10">
        <el-button @click="AdvancedsearchVisibel = false">取 消</el-button>
        <el-button type="primary" @click="confirmserchadvance">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="selSourceVisible">
      <PageHeader class="m-top-10" :border="false" title="选择原产地" />
      <el-form :inline="true">
        <el-form-item label="原产地名称">
          <el-input v-model="selSourcename" />
        </el-form-item>
        <div style="float:right">
          <el-button @click="checkSource">选择数据</el-button>
          <el-button type="primary" @click="getSourceList">查询</el-button>
        </div>
      </el-form>
      <el-table
        ref="SourceTable"
        v-loading="SourceListloading"
        :data="SourceList"
        highlight-current-row
        @current-change="SourceChage"
      >
        <af-table-column property="IDX" label="原产地id" />
        <af-table-column property="SourceAreaName" label="原产地名称" />
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="SourceListTotal"
          :current-page="SourceListParas.PageIndex"
          :page-size="SourceListParas.PageSize"
          layout="prev, pager, next, jumper"
          @current-change="SourceListhandleCurrentChange"
        /></div>
    </el-dialog>
    <el-dialog title="" :visible.sync="selUserVisible">
      <PageHeader class="m-top-10" :border="false" title="选择申请人" />
      <el-form :inline="true">
        <el-form-item label="申请人姓名">
          <el-input v-model="selUsername" />
        </el-form-item>
        <div style="float:right">
          <el-button @click="checkUser">选择数据</el-button>
          <el-button type="primary" @click="getUserList">查询</el-button>
        </div>
      </el-form>
      <el-table
        ref="UserTable"
        v-loading="UserListloading"
        :data="UserList"
        highlight-current-row
        @current-change="UserChage"
      >
        <af-table-column property="UserID" label="用户ID" />
        <af-table-column property="NickName" label="用户昵称" />
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="UserListTotal"
          :current-page="UserListParas.PageIndex"
          :page-size="UserListParas.PageSize"
          layout="prev, pager, next, jumper"
          @current-change="UserListhandleCurrentChange"
        /></div>
    </el-dialog>
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetMaterialRefCompanyList" :list="tableListJson.productInfolist" name="productInfolist" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import productList from '@/minxin/product/productInfoList.js'
export default productList
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
</style>
<style >
.el-button--mini.is-circle {
  padding: 1px
}
.el-button--mini.is-circle {
  padding: 1px
}
</style>
<style lang='scss'>
.advanser .el-form-item__label {
  font-size: 10px
}
.pointer {
    cursor: pointer
}
.merchants{
  position: relative;
  .allChilce{
      height: 25px;
      line-height: 25px;
      .active{
        color: #333;
      }
      .nomr{
         color: #ccc;
      }
  }
  .main_flex{
    // width: 100%;
    min-height: 400px;
    .left{
      width: 400px;
    }
    .right{
      flex: 1;
      min-width: 400px;
    }
  }
  .cropper-content {
    .cropper {
      //  min-width: 500px;
       width: 100%;
       height:  70vh;
    }
}
.absol{
  position: absolute;
  left: 0;
}

}
.test_pop_view{
  min-width: 60px;
}
</style>
