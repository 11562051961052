
import PageHeader from '@/components/PageHeader.vue'
import $post from '@/request/productApis.js'
import root from '@/minxin/root'
import tableMixin from '@/minxin/table-minxin'
const productList = {
  name: 'productInfoList',
  components: {
    PageHeader
    // searchView
  },
  mixins: [tableMixin, root],
  data() {
    return {
      root_name: 'cplb',
      root_msg: '产品列表',
      activeName: '1',
      UserInfo: {}, // 人员信息
      keywords: '',
      value: '',
      // 行高改变的一些字段
      targetTd: null,
      coltargetTd: null,
      resizeable: false,
      mousedown: false,
      // 裁剪组件的基础配置option
      option: {
        view_image: '',
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      options: [],
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: ''
      },
      value1: '',
      active: 0,
      form: {
        IDX: 0,
        ApplyDate: '',
        DepartID: null,
        DepartNames: 'sample 6666',
        FillFormUserID: '',
        ApplyPersonID: 2,
        ApplyPersonName: 2,
        MaterialName: 'test001',
        NickName: 'test001',
        InvCode: 'test001',
        MaterialTypeID: 1,
        InvTypeID: 1,
        InvTypeCode: null,
        BrandID: 1,
        SourceAreaID: null,
        SourceAreaName: null,
        Specification: '1',
        MinPacking: 'test001', // 最小包装
        StorageNo: 'test001', // 库存代码
        StockUnitN: 1, // 单位
        ShelfLifeNum: 36, // 产品有效期
        ShelfLifeType: '3', // 有效期状态：0 长期有效，1 年，2 月，3 日（新增）
        isLong: null, // 产品有效期是否长期
        TransportCondition: 1, // 运输条件
        ManuPartNum: 'test001',
        RegisterNo: 'test001', // 注册证号/批准文号/备案凭证号
        RegisterStart: '2020-01-10',
        RegisterEnd: '2021-06-02',
        RegisterCardType: '0', // 注册证是否是长期有效 0: 否 1：是
        RegisterPerson: 'test001', // 医疗器械注册人
        ProductFactory: 'test001',
        MaterialPermissionPerson: 'test001',
        ProductLicenseNo: 'test001',
        StockCode: 'test001',
        StorageConditionID: null,
        DICode: 'test001',
        MaterialRemark: 'test001',
        Tax: 0, // 产品税率
        TaxType: null, // 税率类别
        PlanPrice: 0,
        Price: 0
      },
      // 品牌
      brandlist: [],
      // 请求列表参数
      listParas: {
        Keyword: '', // 搜索关键字
        SearchType: 0, // 搜索状态：0：模糊搜索，1：高级搜索
        SearchColumn: '', // 需搜索字段，当为高级搜索时可用
        ApproveStatus: -1, // 审批状态：0：待发起，1：审核中，2：已通过， :3：已驳回，4：已禁用
        MaterialSearchType: 0, // 产品检索类别，0：全部，1：药品 2：医疗器械
        PageIndex: 1,
        PageSize: 10,
        QueryType: 1, // 1 全部 2 药品 3 医疗器械
        RegisterNo: '',
        DINum: '',
        ApplyDateStart: '',
        ApplyDateEnd: '',
        MaterialName: '',
        NickName: '',
        BrandName: '',
        InvTypeName: null,
        MaterialTypeName: null,
        ProductFactory: '',
        ProductLicenseNo: '',
        InvCode: '',
        Specification: '',
        StockUnitN: '',
        StorageConditionName: '',
        StorageNo: '',
        SourceAreaName: '',
        SourceAreaNameShow: '',
        ManuPartNum: '',
        ShelfLifeNum: null,
        ShelfLifeType: null,
        DepartNames: null,
        ApplyUserNameShwo: null,
        ApplyUserName: null,
        ApproveState: -1,
        Tax: null,
        StockCode: null
      },
      tableHeight: 'calc(100vh - 340px)',
      tableBackground: true,
      tableLoading: false,
      tableTotalSize: 0,
      tablePageSize: 10,
      pagerCount: 5,
      tableData: [],
      tableDataloading: false,
      // 选择原产地
      selSourcename: '',
      selSourceVisible: false,
      SourceList: [], // 人员
      SourceListloading: false,
      SourceListTotal: 0,
      SourceCurrentRow: null,
      SourceListParas: {
        PageIndex: 1,
        PageSize: 5,
        keyWords: ''
      },
      // 选择申请人
      selUsername: '',
      selUserVisible: false,
      UserList: [], // 人员
      UserListloading: false,
      UserListTotal: 0,
      UserCurrentRow: null,
      UserListParas: {
        PageIndex: 1,
        PageSize: 5,
        Name: '',
        permissionGroupInfoId: 0 // 部门id
      },
      // 高级搜索
      AdvancedsearchVisibel: false,
      InvTypeList: [], // 物料分类
      materialTypeList: [{
        IDX: 1,
        InvTypeCode: '2312',
        InvTypeName: '312312'
      }], // 物料类型
      DepartList: [], // 部门
      ConditionList: [], // 储存 运输条件
      ApproveStateLisst: [
        {
          id: 0,
          value: '待发起'
        },
        {
          id: 1,
          value: '审核中'
        },
        {
          id: 2,
          value: '已通过'
        },
        {
          id: 3,
          value: '已驳回'
        },
        {
          id: 4,
          value: '已禁用'
        }
      ],
      selects: [],
      selectsArr: [],
      // StockCodeList
      StockCodeList: []
    }
  },
  mounted() {
    // 获取按钮权限
    // this.getBottons('cplb')
    // 获取人员信息
    this.getUserinfo()
    // this.getTableDataAsync()
  },
  watch: {
    selects(a) {
      this.selectsArr = []
      if (a.length === 0) return this.selectsStr = ''
      a.map(item => {
        this.selectsArr.push(item.IDX)
      })
      console.log(this.selectsArr)
    }
  },
  methods: {
    // 获取仓位接口
    getLocation() {
      const _this = this
      _this.$api.GetStockCodeList({ KeyWords: '', PageIndex: 1, PageSize: -1 }).then((res) => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.StockCodeList = res.Message
        } else {
          console.log('获取仓位列表出错： ' + res.RetMsg)
        }
      })
    },
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    Turn(url) {
      this.$router.push(url)
    },
    rechaFirst(_idx) {
      const _this = this
      _this.tableDataloading = true
      $post.SaveFirstCampMaterialFromMaterialList({ IDX: _idx }).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          _this.getTableDataAsync()
          _this.$message.success('发起首营成功')
          _this.$route.push({ name: 'ProductApprove1', params: { type: 1, idx: res.Message }})
        } else {
          _this.$message.error('发起首营出错： ' + res.RetMsg)
        }
      })
    },
    // 获取人员信息
    getUserinfo() {
      const _this = this
      $post.getUserInfo().then((res) => {
        if (res.RetCode === '0') {
          _this.UserInfo = res.Message
        }
      })
    },
    clearAdvan() {
      this.listParas.SearchType = 0
      this.listParas.RegisterNo = null
      this.listParas.DINum = null
      this.listParas.ApplyDateStart = null
      this.listParas.ApplyDateEnd = null
      this.listParas.MaterialName = null
      this.listParas.NickName = null
      this.listParas.BrandName = null
      this.listParas.InvTypeName = null
      this.listParas.MaterialTypeName = null
      this.listParas.ProductFactory = null
      this.listParas.ProductLicenseNo = null
      this.listParas.InvCode = null
      this.listParas.Specification = null
      this.listParas.StockUnitN = null
      this.listParas.StorageConditionName = null
      this.listParas.StorageNo = null
      this.listParas.ManuPartNum = null
      this.listParas.StorageConditionID = null
      this.listParas.SourceAreaID = null
      this.listParas.SourceAreaName = null
      this.listParas.ApproveState = -1
      this.listParas.StockCode = null
    },
    confirmserchadvance() {
      this.listParas.PageIndex = 1
      this.getTableDataAsync()
      this.AdvancedsearchVisibel = false
    },
    initadvance() {
      this.getLocation()
      this.getConditionList() // 存储/运输条件
      this.getInvType() // 物料分类
      this.clearAdvan()
    },
    // 初始化数据
    InitEdit() {
      this.getInvType() // 物料分类
      this.getDepartList() // 部门列表
      this.getConditionList() // 存储/运输条件
    },
    OpenAdvanSearch() {
      this.initadvance()
      this.listParas.SearchType = 1
      this.AdvancedsearchVisibel = true
    },
    // 获取储存 运输条件
    getConditionList() {
      const _this = this
      $post.getConditionList().then((res) => {
        _this.ConditionList = res.Message
      })
    },
    // 获取部门
    getDepartList() {
      const _this = this
      $post.getDepartList().then((res) => {
        _this.DepartList = res.Message
      })
    },
    // 获取物料类型
    getMaterialType() {
      const _this = this
      $post.getMaterialType().then((res) => {
        _this.InvTypeList = res.Message
      })
    },
    // 获取物料分类
    getInvType() {
      const _this = this
      $post.getInvTypeList().then((res) => {
        _this.materialTypeList = res.Message
      })
    },
    // 获取人员接口
    getUserListdata() {
      const _this = this
      _this.UserListloading = true
      $post.getUserList(_this.UserListParas).then((res) => {
        _this.UserListloading = false
        _this.UserList = res.Message
        _this.UserListTotal = res.Total
      })
    },
    // 选择申请人
    UserListhandleCurrentChange(val) {
      this.UserListParas.PageIndex = val
      console.log(val)
      this.getUserListdata()
    },
    checkUser() {
      this.listParas.ApplyUserName = this.UserCurrentRow.UserID
      this.listParas.ApplyUserNameShwo = this.UserCurrentRow.NickName
      this.selUserVisible = false
    },
    selUser() {
      this.UserCurrentRow = null
      this.selUserVisible = true
      this.UserList = []
      this.getUserList()
    },
    UserChage(val) {
      console.log(val)
      this.$refs.UserTable.setCurrentRow(val)
      this.UserCurrentRow = val
    },
    // 获取人员
    getUserList() {
      const _this = this
      // _this.UserListParas.permissionGroupInfoId = _this.form.DepartID
      _this.UserListParas.permissionGroupInfoId = -1
      _this.getUserListdata()
    },
    listhandleCurrentChange(val) {
      this.listParas.PageIndex = val
      this.getTableDataAsync()
    },
    // 获取原产地数据接口
    getresourceListdata() {
      const _this = this
      _this.SourceListloading = true
      $post.getSorceList(_this.SourceListParas).then((res) => {
        _this.SourceListloading = false
        _this.SourceList = res.Message
        _this.SourceListTotal = res.Total
      })
    },
    // 选择原产地
    SourceListhandleCurrentChange(val) {
      this.SourceListParas.PageIndex = val
      this.getresourceListdata()
    },
    checkSource() {
      this.listParas.SourceAreaName = this.SourceCurrentRow.IDX
      this.listParas.SourceAreaNameShow = this.SourceCurrentRow.SourceAreaName
      this.selSourceVisible = false
    },
    selSource() {
      this.SourceCurrentRow = null
      this.selSourceVisible = true
      this.SourceList = []
      this.SourceListParas.keyWords = ''
      this.getSourceList()
    },
    SourceChage(val) {
      console.log(val)
      this.$refs.SourceTable.setCurrentRow(val)
      this.SourceCurrentRow = val
    },
    // 获取原产地
    getSourceList() {
      const _this = this
      _this.getresourceListdata()
    },
    // 导出
    exportData() {
      const _this = this
      _this.tableDataloading = true
      this.listParas.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.listParas.SelectIDList = this.selectsArr
      this.$refs.ExportLoading.open()

      $post.ExportMaterialRefCompanyInfoByUserField(_this.listParas).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          // _this.downloadFile(res.Message, _this.guid() + '.xlsx')
          const name = this.$minCommon.getExportName('产品列表')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return _this.$message.error('导出数据出错：' + res.RetMsg)
      })
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    guid() {
      return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
    },
    // 处理字节流
    downloadFile(data, fileName) {
      var byteString = atob(data)
      var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer) // 创建视图
      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([intArray], { type: '' })
      // 获取heads中的filename文件名
      const downloadElement = document.createElement('a')
      // 创建下载的链接
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      // 下载后文件名
      downloadElement.download = fileName
      document.body.appendChild(downloadElement)
      // 点击下载
      downloadElement.click()
      // 下载完成移除元素
      document.body.removeChild(downloadElement)
      // 释放掉blob对象
      window.URL.revokeObjectURL(href)
    },
    // 下载zip
    downloadZip(_JsonStr) {
      const _this = this
      const _arr = JSON.parse(_JsonStr)
      if (!_arr || _arr.length < 1) {
        _this.$message.error('没有可供下载的证件')
        return false
      }
      const paras = {
        JsonStr: _JsonStr
      }
      $post.DownloadFirstCampZip(paras).then((res) => {
        if (res.RetCode === '0') {
          _this.downloadFile(res.Message, _this.guid() + '.zip')
        } else {
          _this.$message(res.RetMsg)
        }
      })
    },
    // 禁用 启用产品
    EnableProduct(_idx, type) {
      const _this = this
      $post.EditMaterialInfoState({ FCMIDX: _idx, ApproveState: type }).then((res) => {
        if (res.RetCode === '0') {
          _this.$message({
            message: '操作成功',
            type: 'success'
          })
          _this.getTableDataAsync()
        } else {
          _this.$message({
            message: res.RetMsg,
            type: 'error'
          })
        }
      })
    },
    activeNameClick() {
      console.log(this.activeName)
      this.listParas.QueryType = this.activeName
      this.getTableDataAsync()
    },
    async  getTableDataAsync() {
      const _this = this
      _this.tableDataloading = true
      const res = await $post.GetMaterialRefCompanyList(_this.listParas)
      const { Message, Total } = res
      _this.tableData = Message
      _this.tableTotalSize = Total
      _this.tableDataloading = false
      console.log(' _this.tableData', _this.tableData)
      return res
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    filterTag(value, row) {
      return row.Status === value
    },
    getInfo(index) {
      switch (index) {
        case 2:
          return { info: '正常', type: 'primary' }
        case 0:
          return { info: '编辑中', type: 'success' }
        case 4:
          return { info: '已禁用', type: 'info' }
        default:
          return { info: '暂无', type: 'warning' }
      }
    },
    toStep(n) {
      console.log(n)
      this.active = n
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      $post.getProductInfo({ FCMIdx: _idx }).then(res => {
        if (res.RetCode === '0') {
          _this.materialdetaisinfo = res.Message
        }
      })
    },
    getShefType(_type) {
      switch (_type) {
        case 1:
          return { value: 1, text: '年' }
        case 2:
          return { value: 2, text: '月' }
        case 3:
          return { value: 3, text: '日' }
        default: return { value: 0, text: '' }
      }
    },
    getTaxInfo(_tax) {
      switch (_tax) {
        case 0.17:
          return '17'
        case 0.16:
          return '16'
        case 0.13:
          return '13'
        case 0.11:
          return '11'
        case 0.1:
          return '10'
        case 0.06:
          return '6'
        case 0.03:
          return '3'
        case 0.01:
          return '1'
        case 1:
          return '0'
        default: return null
      }
    }
  }
}
export default productList
